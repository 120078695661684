import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '@common/Layout';
import { Container } from '@components/global';
import NavbarSimple from '@common/NavbarSimple';
import Footer from '@sections/Footer';

const Page = styled.div`
  h1,
  h2 {
    color: ${props => props.theme.color.primary};
    font-weight: bold;
  }

  h1 {
    font-size: 2.5rem;
    margin-top: 2rem;
  }
  h2 {
    font-size: 1.6rem;
    margin-top: 1.8rem;
  }
  p {
    font-size; 1rem;
    color: ${props => props.theme.color.secondary}
  }

  h2 ~ p {
    margin-top: 0.5rem;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  color: ${props => props.theme.color.secondary}
  font-size: 1.4rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SecondPage = () => (
  <Layout>
    <NavbarSimple />
    <Container>
      <Page>
        <h1>IMPRESSUM</h1>
        <h2>Physiotherapie Eva-Maria Schneglberger</h2>
        <p>
          Freundorf 4
          <br />
          A-4792 Münzkirchen <br />
          praxis@physio-evamaria.at
        </p>
        <p>
          Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:{' '}
          <strong>Eva-Maria Schneglberger</strong> (Anschrift wie oben)
        </p>

        <h2>Datenschutz</h2>
        <p>
          Wir, von Physiotherapie Eva-Maria Schneglberger, nehmen den Schutz
          Ihrer persönlichen Daten sehr ernst und halten uns strikt an die
          Regeln der Datenschutzgesetze. Personenbezogene Daten werden auf
          dieser Webseite nur im technisch notwendigen Umfang erhoben. In keinem
          Fall werden die erhobenen Daten verkauft oder aus anderen Gründen an
          Dritte weitergegeben. Die nachfolgende Erklärung gibt Ihnen einen
          Überblick darüber, wie wir diesen Schutz gewährleisten und welche Art
          von Daten zu welchem Zweck erhoben werden.
        </p>
        <h2>Datenverarbeitung auf dieser Internetseite</h2>
        <p>
          Physiotherapie Eva-Maria Schneglberger erhebt und speichert
          automatisch in ihren Server Log Files Informationen, die Ihr Browser
          an uns übermittelt.
        </p>
        <p>
          Diese sind: Browsertyp/ -version verwendetes Betriebssystem Referrer
          URL (die zuvor besuchte Seite) Uhrzeit der Serveranfrage Hostname des
          zugreifenden Rechners (IP Adresse) Diese Daten sind für Physiotherapie
          Eva-Maria Schneglberger nicht bestimmten Personen zuordenbar. Eine
          Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen, die Daten werden zudem nach einer statistischen
          Auswertung gelöscht.
        </p>
        <h2>Emails</h2>
        <p>
          Daten werden auch erfasst und gespeichert, wenn Sie Emails an uns
          senden. Ihre EMail-Adresse wird nicht an Dritte weitergegeben (z.B.
          Werbefirmen).
        </p>
        <h2>Weitere Informationen</h2>
        <p>
          Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit Rede
          und Antwort bezüglich der Verarbeitung Ihrer personenbezogenen Daten
          stehen. Wenn Sie Fragen haben, die Ihnen diese Datenschutzerklärung
          nicht beantworten konnte oder wenn Sie zu einem Punkt vertiefte
          Informationen wünschen, wenden Sie sich bitte jederzeit an uns.
          Telefon: +43 (0)664 750 89669 Mail: praxis@physio-evamaria.at, Freundorf 4/3, A-4792 Münzkirchen
        </p>
        <StyledLink to="/">Zurück zur Startseite</StyledLink>
      </Page>
    </Container>
    <Footer />
  </Layout>
);

export default SecondPage;
