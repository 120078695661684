import React, { Component } from 'react';
import { Link } from 'gatsby';

import { Nav, Brand, StyledContainer } from './style';

import { ReactComponent as Logo } from '@static/logos/logo.svg';

class NavbarSimple extends Component {
  render() {
    return (
      <Nav {...this.props}>
        <StyledContainer>
          <Link to="/">
            <Brand>{Logo()}</Brand>
          </Link>
        </StyledContainer>
      </Nav>
    );
  }
}

export default NavbarSimple;
