import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

import { Container } from '@components/global';

import { ReactComponent as Logo } from '@static/logos/logo.svg';

const StyledLink = styled(Link)`
  font-size: 0.875rem;
  color: ${props => props.theme.color.secondary};
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${props => props.theme.color.primary};
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    margin-top: 2rem;
  }
`;

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <FooterWrapper>
          <StyledContainer>
            <Copyright>{Logo()}</Copyright>
            <StyledLink to="/impressum">
              Impressum & Datenschutzerklärung
            </StyledLink>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.tealLight};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  svg {
    width: 200px;
    height: auto;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
