import styled from 'styled-components';

import { Container } from '@components/global';

export const Nav = styled.nav`
  padding: 8px 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  top: 0;
  z-index: 1000;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Brand = styled.div`
  font-family: ${props => props.theme.font.primary};
  ${props => props.theme.font_size.large};
  display: flex;
  svg {
    width: 190px;

    @media (max-width: ${props => props.theme.screen.xs}) {
      max-height: 55px;
    }
  }
`;
